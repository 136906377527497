import React, { useState, useEffect, useRef } from "react";
import { db, signOut, auth } from "../firebase";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, getDoc } from "firebase/firestore";
import backgroundImage from "../images/sinan_canan.jpg";
import logo from "../images/acmsecondlogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import ColorThief from "color-thief-browser";

function AdminDashboard() {
  const [selectedEventId, setSelectedEventId] = useState("");
  const [participants, setParticipants] = useState([]);
  const [products, setProducts] = useState([]);
  const [groupedProducts, setGroupedProducts] = useState([]);
  const [productName, setProductName] = useState("");
  const [winners, setWinners] = useState([]);
  const [productCount, setProductCount] = useState(1);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [itemColor, setItemColor] = useState("");
  const imageRef = useRef(backgroundImage);

  const location = useLocation();
  const { eventId } = location.state || {};
  const productMap = products.reduce((map, product) => {
    map[product.id] = product.name;
    return map;
  }, {});

  const navigate = useNavigate();

  useEffect(() => {
    if (eventId) {
      setSelectedEventId(eventId);
    }
  }, [eventId]);

  useEffect(() => {
    const fetchParticipants = async () => {
      const participantsCollection = collection(db, "participants");
      const participantsSnapshot = await getDocs(participantsCollection);
      const participantsList = participantsSnapshot.docs
        .map((doc) => doc.data())
        .filter((participant) => participant.eventId === selectedEventId);
      setParticipants(participantsList);
    };

    fetchParticipants();
  }, [selectedEventId]);

  useEffect(() => {
    const fetchEventDetails = async () => {
      if (!selectedEventId) return;

      const productsCollection = collection(db, "products");
      const productsSnapshot = await getDocs(productsCollection);
      const productsList = productsSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((product) => product.eventId === selectedEventId);

      setProducts(productsList);

      // Group products by name and count them
      const grouped = productsList.reduce((acc, product) => {
        if (acc[product.name]) {
          acc[product.name].count += 1;
        } else {
          acc[product.name] = { ...product, count: 1 };
        }
        return acc;
      }, {});

      setGroupedProducts(Object.values(grouped));

      // Fetch winners for each product and update the products data
      const productsWithWinners = await Promise.all(
        productsList.map(async (product) => {
          const productDocRef = doc(db, "products", product.id);
          const productDocSnapshot = await getDoc(productDocRef);

          if (productDocSnapshot.exists()) {
            const productData = productDocSnapshot.data();
            return {
              ...product,
              winners: productData.winners || [], // Ensure winners are included
            };
          } else {
            return product;
          }
        })
      );

      // Set the updated products with winners to state
      setProducts(productsWithWinners);

      // Set the winners state to show existing winners from Firestore
      const allWinners = productsWithWinners.flatMap((product) => product.winners || []);
      setWinners(allWinners);
    };

    fetchEventDetails();
  }, [selectedEventId]);

  const handleAddProduct = async () => {
    if (productName && selectedEventId) {
      try {
        await addDoc(collection(db, "products"), {
          name: productName,
          eventId: selectedEventId,
          number: productCount, // Store the count as part of the document
          createdAt: new Date(),
        });
        setProductName("");
        setProductCount(1); // Reset the count to default after addition
        alert(`Product "${productName}" added successfully with count: ${productCount}`);
      } catch (error) {
        console.error("Error adding product: ", error);
      }
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await deleteDoc(doc(db, "products", productId));
      setProducts((prevProducts) => prevProducts.filter((product) => product.id !== productId));
      alert("Product deleted successfully!");
    } catch (error) {
      console.error("Error deleting product: ", error);
    }
  };

  const handlePickWinner = async (productId, productCount) => {
    if (participants.length === 0) {
      alert("No participants to choose from for this product!");
      return;
    }

    // Filter out winners already selected
    const winnerPhones = winners.map((entry) => entry.winner.phone);
    let availableParticipants = participants.filter(
      (participant) => !winnerPhones.includes(participant.phone)
    );

    if (availableParticipants.length < productCount) {
      alert("Not enough participants to select all winners!");
      return;
    }

    const selectedWinners = [];
    for (let i = 0; i < productCount; i++) {
      // Pick a random participant
      const randomIndex = Math.floor(Math.random() * availableParticipants.length);
      const selectedWinner = availableParticipants[randomIndex];

      selectedWinners.push(selectedWinner);

      // Remove the selected winner from the available participants pool
      availableParticipants = availableParticipants.filter(
        (participant) => participant.phone !== selectedWinner.phone
      );
    }

    // Update the winners state
    const newWinners = selectedWinners.map((winner) => ({
      productId: productId,
      winner: winner,
    }));

    setWinners((prevWinners) => [...prevWinners, ...newWinners]);

    // Update the backend with winners
    try {
      const productDocRef = doc(db, "products", productId);
      await updateDoc(productDocRef, {
        winners: newWinners.map((entry) => ({
          productId: entry.productId,
          winner: entry.winner,
        })),
      });
      alert(`Winners selected for ${productCount} unit(s) of this product!`);
    } catch (error) {
      console.error("Error updating product winners: ", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully.");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  
  const handleBack = () => {
    navigate("/select-event/")
  }

  

  useEffect(() => {
    const extractColor = async () => {
      if (imageRef.current) {
        const colorThief = new ColorThief();
  
        // Extract the palette (up to 5 colors)
        const palette = colorThief.getPalette(imageRef.current, 5);
  
        // Lighten the colors for better visibility
        const lightenColor = (color) =>
          color.map((value) => Math.min(value + 40, 255));
  
        const gradientColors = palette.map(
          (color) => `rgb(${lightenColor(color).join(",")})`
        );
  
        // Create a CSS gradient string
        const gradient = `linear-gradient(135deg, ${gradientColors.join(", ")})`;
  
        setBackgroundColor(gradient);
        setItemColor(gradientColors[0]); // Use the first color for items
      }
    };
  
    if (imageRef.current?.complete) {
      extractColor();
    } else {
      imageRef.current.onload = extractColor;
    }
  }, []);
  
  return (
    <div className="flex min-h-screen justify-between" style={{ background: backgroundColor }}>
      <section className="p-8 w-1/5 flex-grow overflow-y-auto max-h-[100vh]">
          <div className="flex items-center gap-10 my-4">
            <button className="text-white text-2xl px-4 py-2 rounded-full " style={{background: itemColor}}
              onClick={handleBack}>
              {"<"}
            </button>
            <div>
            <p className="m-2 text-white text-2xl px-6 py-2 rounded-lg cursor-default select-none" style={{background: itemColor}}>
              {selectedEventId}
            </p>
            <p className="text-white text-center text-2xl px-6 py-2 rounded-lg cursor-default select-none" style={{background: itemColor}}>Products</p>
            </div>
          </div>
        
        <ul className="space-y-4">
        {groupedProducts.map((product) => (
              <li key={product.id} className="p-4 bg-gray-100 rounded-md">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-semibold leading-6">{product.name} x ({product.number})</span>
                  <div className="flex space-x-2">
                    {product.winners ? <p className="bg-purple-500 text-white px-4 py-2 rounded-md select-none cursor-default">Çekildi!</p> : 
                    <button
                      onClick={() => handlePickWinner(product.id, product.number)}
                      className="bg-purple-500 text-white px-4 py-2 rounded-md hover:bg-purple-600"
                    >
                      Kazananı Seç!
                    </button>}
                    
                    <button
                      onClick={() => handleDeleteProduct(product.id)}
                      className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                    >
                      Sil
                    </button>
                  </div>
                </div>
            </li>
          ))}
        
        </ul>

        <div className="my-6">
          <input
            type="text"
            placeholder="Product Name"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md mb-4"
          />
          <div className="flex items-center">
            <input
              type="number"
              min="1"
              value={productCount}
              onChange={(e) => setProductCount(Number(e.target.value))}
              className="w-1/3 px-4 py-2 border border-gray-300 rounded-md mb-4"
            />
            <div className="w-2/5 mx-2 px-2 py-2 border border-gray-300 rounded-md mb-4 bg-white cursor-default select-none">
              <span className="ml-2">Ürün Sayısı</span>
            </div>
          </div>
          <button
            onClick={handleAddProduct}
            className="bg-purple-500 text-white px-6 py-2 rounded-md hover:bg-purple-600"
          >
            Add Products
          </button>
        </div>

        <button
          onClick={handleSignOut}
          className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
        >
          Sign Out
        </button>
          {/*Logo*/}
        <div>
          <img
          src={logo}
          alt="Logo"
          className="w-[310px] h-[132px] mt-40"
          />
        </div>
      </section>

      <section>
        <img src={backgroundImage} alt="Background" className="w-auto h-screen" ref={imageRef}/>
      </section>
      <section className="p-8 w-1/5 flex-grow overflow-y-auto max-h-[100vh]" style={{background: backgroundColor}}>
        <p className="m-2 text-white text-center  text-2xl py-2 rounded-lg cursor-default select-none" style={{background: itemColor}}>Winners</p>
        {winners.map((entry, index) => (
          <div key={index} className="p-4 bg-white shadow-md rounded-md mt-4">
            <h3 className="text-xl font-semibold">Winner:</h3>
            <p>
              Ürün: <strong>{productMap[entry.productId]}</strong>
            </p>
            <p>
              Katılımcı: {entry.winner.name} {entry.winner.surname} ({entry.winner.phone})
            </p>
          </div>
        ))}
      </section>
    </div>
  );
}

export default AdminDashboard;
